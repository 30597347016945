import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { ModerationStatusType, Photo } from '../models/photo';
import { Comment } from '../models/comment';
import { MessageService } from './message.service';
import { Page, queryPaginated, queryPaginatedAll, queryPaginatedAllLimit } from '../pagination';
import { NGXLogger } from 'ngx-logger';
import { AuthenticationService } from '@/_services';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from './url.service';
import { StatusType } from '@/models/serie';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json', 'Authorization': 'Basic ' +
      btoa('serge.frezefond@gmail.com:manager12')
  })
};

@Injectable({ providedIn: 'root' })
export class PhotoService {
  public fullscreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private urlService: UrlService,
    private logger: NGXLogger) {
    this.logger.debug('constructor episodeservice  ');
  }



  /** GET photos from the server */
  getEpisodePhotos(episode_id: number): Observable<Photo[]> {
    const episode_photosUrl = this.urlService.getEpisodesUrl() + episode_id + '/photos/?ordering=numero&moderation_status!=' + ModerationStatusType.MODERATION_STATUS_REJECTED;
    const params = new HttpParams().set('limit', String(100));
    return queryPaginatedAllLimit<Photo>(this.http, episode_photosUrl, 100)
      .pipe(
        map(photos => photos.results),
        shareReplay(1),
        tap(photos => this.log(`fetched photos`)),
        catchError(this.handleError('getPhotos', []))
      );
  }

  /** GET photos from the server */
  getPhotos(): Observable<Photo[]> {
    return this.http.get<Photo[]>(this.urlService.getPhotosUrl() + '?status=' + StatusType.PUBLIC_STATUS, httpOptions)
      .pipe(
        tap(photos => this.log(`fetched photos`)),
        catchError(this.handleError('getPhotos', []))
      );
  }

  getPhotosList(urlOrFilter?: string | object): Observable<Page<Photo>> {
    return queryPaginated<Photo>(this.http, this.urlService.getPhotosUrl(), urlOrFilter);
  }

  getPhotosListAll(): Observable<Page<Photo>> {
    return queryPaginatedAllLimit<Photo>(this.http, this.urlService.getPhotosUrl(), 1000);
  }

  /** GET photo by id. Return `undefined` when id not found */
  getPhotoNo404<Data>(id: number): Observable<Photo> {
    const url = this.urlService.getPhotosUrl() + `?id=${id}/`;
    return this.http.get<Photo[]>(url)
      .pipe(
        map(photos => photos[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} photo id=${id}`);
        }),
        catchError(this.handleError<Photo>(`getPhoto id=${id}`))
      );
  }

  /** GET photo by id. Will 404 if id not found */
  getPhoto(id: number): Observable<Photo> {
    const url = this.urlService.getPhotosUrl() + `${id}/`;
    return this.http.get<Photo>(url, httpOptions).pipe(
      tap(_ => this.log(`fetched photo id=${id}`)),
      catchError(this.handleError<Photo>(`getPhoto id=${id}`)
      ),
      shareReplay()
    );
  }

  getMyLikedPhotos(): Observable<Photo[]> {
    return queryPaginatedAllLimit<Photo>(this.http, this.urlService.getPhotosUrl() + 'mylikedphotos/', 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getMyLikedPhotos', []))
      );
  }

  getMySharedPhotos(): Observable<Photo[]> {
    return queryPaginatedAllLimit<Photo>(this.http, this.urlService.getPhotosUrl() + 'mysharedphotos/', 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getMySharedPhotos', []))
      );
  }

  /** GET photos from the server */
  getMyPhotoshoots(): Observable<Photo[]> {
    const myphotoshootsUrl = this.urlService.getPhotosUrl() + 'myphotoshoots/';
    return queryPaginatedAllLimit<Photo>(this.http, myphotoshootsUrl, 100)
      .pipe(
        map(photos => photos.results),
        tap(photos => this.log(`fetched photos`)),
        catchError(this.handleError('getPhotos', []))
      );
  }
  /** GET photos from the server */
  getPhotoshoots(): Observable<Photo[]> {
    // const photoshootsUrl = this.urlService.getPhotosUrl() + 'photoshoots/';
    const photoshootsUrl = this.urlService.getEpisodesUrl() + '1/photos/?status=' + StatusType.PUBLIC_STATUS;
    // const photoshootsUrl = this.urlService.getEpisodesUrl() + '1/photos/?moderation_status!=' + ModerationStatusType.MODERATION_STATUS_REJECTED;
    return queryPaginatedAllLimit<Photo>(this.http, photoshootsUrl, 100)
      .pipe(
        map(photos => photos.results),
        tap(photos => this.log(`fetched photos`)),
        catchError(this.handleError('getPhotos', []))
      );
  }
  /* GET photos whose name contains search term */
  searchPhotos(term: string): Observable<Photo[]> {
    if (!term.trim()) {
      // if not search term, return empty photo array.
      return of([]);
    }
    return this.http.get<Photo[]>(this.urlService.getPhotosUrl() + `?name=${term}`).pipe(
      tap(_ => this.log(`found photos matching "${term}"`)),
      catchError(this.handleError<Photo[]>('searchPhotos', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new photo to the server */
  addPhoto(photo: Photo): Observable<Photo> {
    photo.initial_language = this.translate.currentLang;
    return this.http.post<Photo>(this.urlService.getPhotosUrl(), photo, httpOptions).pipe(
      tap((xphoto: Photo) => this.log(`added photo w/ id=${xphoto.id}`)),
      catchError(this.handleError<Photo>('addPhoto'))
    );
  }

  /** DELETE: delete the photo from the server */
  deletePhoto(photo: Photo | number): Observable<Photo> {
    this.logger.debug('photoservice deletePhoto ' + photo);
    const id = typeof photo === 'number' ? photo : photo.id;
    const url = this.urlService.getPhotosUrl() + `${id}/`;
    return this.http.delete<Photo>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted photo id=${id}`)),
      catchError(this.handleError<Photo>('deletePhoto'))
    );
  }

  /** PUT: update the photo on the server */
  updatePhoto(photo: Photo): Observable<any> {
    return this.http.put(this.urlService.getPhotosUrl(), photo, httpOptions).pipe(
      tap(_ => this.log(`updated photo id=${photo.id}`)),
      catchError(this.handleError<any>('updatePhoto'))
    );
  }


  /** DELETE: delete the photo from the server */
  moveUp(photo: Photo | number): Observable<Photo> {
    this.logger.debug('photoservice moveUp ' + photo);
    const id = typeof photo === 'number' ? photo : photo.id;
    const url = this.urlService.getPhotosUrl() + `${id}/move_up/`;
    return this.http.post<Photo>(url, httpOptions).pipe(
      tap(_ => this.log(`moveUp photo id=${id}`)),
      catchError(this.handleError<Photo>('moveUp'))
    );
  }


  /** DELETE: delete the photo from the server */
  moveDown(photo: Photo | number): Observable<Photo> {
    this.logger.debug('photoservice moveUp ' + photo);
    const id = typeof photo === 'number' ? photo : photo.id;
    const url = this.urlService.getPhotosUrl() + `${id}/move_down/`;
    return this.http.post<Photo>(url, httpOptions).pipe(
      tap(_ => this.log(`moveUp photo id=${id}`)),
      catchError(this.handleError<Photo>('moveUp'))
    );
  }


  likePhoto(photo: Photo): Observable<any> {
    // this.authenticationService.currentUserValue.id,

    return this.http.post(this.urlService.getLikesUrl(),
      {
        owner: this.authenticationService.currentUserValue.id,
        // to_photo: photo.id
        to_content_object: this.urlService.getApiUrlPath() + `photos/${photo.id}/`
        // to_content_object: `/fr/1.0/api/photos/${photo.id}/`
      }, httpOptions);
  }

  // bookmarkPhoto(photo: Photo): Observable<any> {
  //   // this.authenticationService.currentUserValue.id,

  //   return this.http.post(this.urlService.getBookmarksUrl(),
  //     {
  //       from_reader: this.authenticationService.currentUserValue.id,
  //       // to_photo: photo.id
  //       to_content_object: `/fr/1.0/api/photos/${photo.id}/`
  //     }, httpOptions);
  // }

  islikedbyme(photoId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getPhotosUrl() + photoId + '/islikedbyme/', httpOptions);
  }
  isbookmarkedbyme(photoId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getPhotosUrl() + photoId + '/isbookmarkedbyme/', httpOptions);
  }
  iscommentedbyme(photoId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getPhotosUrl() + photoId + '/iscommentedbyme/', httpOptions);
  }

  nblikes(photoId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getPhotosUrl() + photoId + '/nblikes/',
      httpOptions);
  }
  nbcomments(photoId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getPhotosUrl() + photoId + '/nbcomments/',
      httpOptions);
  }

  getPhotoComments(photo_id: number): Observable<Comment[]> {
    return queryPaginatedAllLimit<Comment>(this.http, this.urlService.getPhotosUrl() + photo_id + '/comments/?ordering=-id', 100)
      .pipe(
        map(events => {
          return events.results;
        }
        ),
        shareReplay(),
        tap(episodes => this.logger.debug(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getFullscreen(): Observable<boolean> {
    return this.fullscreen.asObservable();
  }

  public changeFullscreen(status: boolean) {
    this.logger.debug('photoservice changefullscreen');
    this.fullscreen.next(status);
    this.logger.debug('photoservice changefullscreen ' + this.fullscreen.value);
  }

  public toggleFullscreen(status: boolean) {
    this.logger.debug('photoservice toggleFullscreen');
    this.fullscreen.next(!this.fullscreen.value);
    this.logger.debug('photoservice changefullscreen ' + this.fullscreen.value);
  }

  /** Log a PhotoService message with the MessageService */
  private log(message: string) {
    this.messageService.add('PhotoService: ' + message);
  }
}
