import { Component, OnInit, Input, Output, Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '@/models/user';
// import { User } from '../_models';
import { FileValidator } from '@/serie-form/file-input.validator';
import { UploadService } from '@/services/upload.service';
import { ProfileService } from '@/services/profile.service';
import { AuthenticationService } from '@/_services';
import { UserService } from '@/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { ImageService } from '@/services/image.service';
import { FullscreenImageDialogComponent } from '@/fullscreen-image-dialog/fullscreen-image-dialog.component';
import { MatDialog } from '@angular/material/dialog';
// import custom validator to validate that password and confirm password fields match
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public profileForm: FormGroup;
  @Input() userId: number;
  @Output() fileName: string;
  buttonText = 'Update';
  preview: string;
  public file: File;
  user: User;
  get email() { return this.profileForm.get('email'); }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  constructor(private fb: FormBuilder,
    @Inject(LOCALE_ID) private locale: string,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private location: Location,
    private userService: UserService,
    private uploadService: UploadService,
    public imageService: ImageService,
    public dialog: MatDialog,
    private logger: NGXLogger
  ) {
    this.logger.debug('profile constructor');

    // this.profileForm = this.fb.group({
    //   'first_name': ['', Validators.required],
    //   'last_name': ['', Validators.required],
    //   'pseudo': ['', Validators.required],
    //   'bio': [''],
    //   'email': ['', Validators.required],
    //   'file': ['', FileValidator.validate]
    // });
  }

  ngOnInit() {

    this.logger.debug('profileForm ngOnInit');
    this.user = this.authenticationService.currentUserValue;
    this.logger.debug('nginit user:  email' + this.user.email);

    this.profileForm = this.fb.group({
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'pseudo': ['', Validators.required],
      'bio': ['mabio', Validators.minLength(5)],
      'email': ['xxx@gmail.com', [Validators.required, Validators.email]],
      'file': ['', FileValidator.validate]
    });

    this.initializeForm();
  }

  initializeForm() {
    this.logger.debug('initilizeForm');
    this.userService.getById(this.user.id).subscribe(user => {
      this.user = user;
      this.logger.debug('initilizeForm user: ' + JSON.stringify(this.user));

      this.profileForm.patchValue({
        'first_name': this.user.first_name,
        'last_name': this.user.last_name,
        'pseudo': this.user.pseudo,
        'bio': this.user.bio,
        'email': this.user.email,
        //   file: 'Complete me'
      });
      this.logger.debug('initilizeFormi done');
    });

  }

  onFileChange(event) {
    const file = event.target.files[0];
    this.file = event.target.files[0];
    this.fileName = file.name;
    this.logger.debug('onFileChange file.name : ', file.name);
    this.logger.debug('onFileChange this fileName : ', this.fileName);
    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
      this.logger.debug('onFileChddange this preview : ', this.preview);
    };
    reader.readAsDataURL(file);
  }


  onSubmit() {

    let fileURL = '';

    // const fileURL = this.uploadService.getBaseUrl();
    if (this.file) {
      // fileURL = this.uploadService.upload(this.file);
      fileURL = this.uploadService.uploadWithTags(this.file,
        {
          "photo_type": 'user',
          "object_id": this.authenticationService.currentUserValue.id,
          "pseudo": this.authenticationService.currentUserValue.pseudo,
          "user_id": this.authenticationService.currentUserValue.id,
          "date": formatDate(Date.now(), 'yyyyMMddHHmmss', this.locale)
          // "date": formatDate(Date.now(), 'yyyyMMddHHmm', this.locale)
        });
      console.log('SerieFormComponent uploaded fileURLs: ' + fileURL);
    }


    const formValue = this.profileForm.value;
    const newUser = new User();
    newUser.id = this.user.id;
    console.log('you submitted value: ', formValue);
    newUser.first_name = formValue['first_name'];
    newUser.last_name = formValue['last_name'];
    newUser.pseudo = formValue['pseudo'];
    newUser.bio = formValue['bio'];
    newUser.email = formValue['email'];
    newUser.photourl = null;
    newUser.photoname = fileURL;
    console.log('ProfileComponent photourl: ', newUser.photourl);

    console.log('going to update User Profile: ', formValue);

    // const myObserver = {
    //   next: _data => this.router.navigate(['/profile/' + this.userId]),
    //   error: err => console.log(err),
    //   complete: () => console.log('Execution completed')
    // };

    // on doit etre sure qu'un utilisateur ne peut mettre à jour que son profil
    this.userService.update(newUser).subscribe({
      // next: _data => this.router.navigate(['/profile/' + this.user.id]),
      next: _data => this.goBack(),
      error: err => console.log(err),
      complete: () => console.log('Execution completed')
    });
  }

  goBack(): void {
    this.location.back();
  }

  onCancel() {
    this.profileForm.reset();
    this.router.navigate(['/home'], { relativeTo: this.route });
  }

  openfullscreenimageDialog() {
    this.imageService.ogphotourl(this.user.photocontainer, this.user.photoname).subscribe(
      x => {
        const dialog_ref = this.dialog.open(FullscreenImageDialogComponent, {
          panelClass: ['full-screen'],
          data: { imgUrl: x },
          // data: { imgUrl: this.imageService.ogphotourl(this.author.photocontainer, this.author.photoname) },
        });
        dialog_ref.afterClosed().subscribe(result => { });
      });
  }


}
